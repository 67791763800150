import 'vite/modulepreload-polyfill';

import Vue from 'vue';
import './assets/css/login-main.css';
import App from './pages/Login/HrbrLogin.vue';

new Vue({
  el: '#login-app',
  render: (h) => h(App),
});
