<script setup>
/* global google */
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';
import { firebaseConfig, firebaseGoogleClientId } from '@/config/firebase';
import { ref, onMounted } from 'vue';
import checkIconSvg from './icons/check.svg?raw';

const firebaseUiConfig = {
  signInOptions: [
    {
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      providerName: 'Google',
      buttonColor: '#1C73E8',
      textColor: '#FFFFFF',
      customParameters: {
        prompt: 'select_account',
      },
    },
    {
      authname: 'microsoft',
      provider: 'microsoft.com',
      providerName: 'Microsoft',
      buttonColor: '#2F2F2F',
      iconUrl: `https://docs.microsoft.com/en-us/azure/active-directory/develop/media/howto-add-branding-in-azure-ad-apps/ms-symbollockup_mssymbol_19.png`,
      customParameters: {
        prompt: 'select_account',
      },
    },
    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
      forceSameDevice: false,
      requireDisplayName: true,
      fullLabel: 'Passwordless sign-in with email',
      buttonColor: 'transparent',
    },
  ],
  signInFlow: 'popup',
  signInSuccessUrl: 'http://www.batman.com', // required, but not actually used (see custom callback below)
  immediateFederatedRedirect: false,
  credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO,
  callbacks: {
    signInSuccessWithAuthResult(authResult, redirectUrl) {
      // disable auto-redirect (manually store server session first)
      return false;
    },
  },
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

let destinationUrl = null;
let quickAction = null;

const quickActionUrls = {
  newdraft: '/drafts?quickaction=newdraft',
};

const isFirebaseUiVisible = ref(true);
const isSuccessMsgVisible = ref(false);

function listenAuthStateChange() {
  firebaseApp.auth().onAuthStateChanged((user) => {
    if (user) {
      hideFirebaseLogin();
      hideGoogleOneTapLogin();
      sendCredentials(user, (err, data) => {
        if (err) return;
        let url = destinationUrl;
        if (quickAction) {
          let quickActionUrl = quickActionUrls[quickAction];
          if (quickActionUrl) url = quickActionUrl;
        }
        window.location.replace(`/logindestinationredirect?destination_url=${encodeURIComponent(url)}`); // prettier-ignore
      });
    } else {
      showFirebaseLogin();
      showGoogleOneTapLogin();
    }
  });
}

async function sendCredentials(user, callback) {
  const tokenId = await user.getIdToken();
  setPersistence('NONE');
  try {
    const data = await sendCredentialsRequest(tokenId);
    callback(null, data);
  } catch (err) {
    callback(err);
    console.error(err);
    alert(`Error - we could not validate your secure credentials at this point. Try again in a bit or contact support@harbourshare.com`); // prettier-ignore
  }
}

async function sendCredentialsRequest(tokenId) {
  const resp = await fetch('/loginstore', {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ tokenId }),
    credentials: 'include',
  });
  return await resp.json();
}

function initFirebaseUi() {
  const ui = new firebaseui.auth.AuthUI(firebase.auth());
  ui.start('#firebaseui-auth-container', firebaseUiConfig);
}

function showFirebaseLogin() {
  isFirebaseUiVisible.value = true;
  isSuccessMsgVisible.value = false;
  setPersistence('SESSION');
}

function hideFirebaseLogin() {
  isFirebaseUiVisible.value = false;
  isSuccessMsgVisible.value = true;
}

function showGoogleOneTapLogin() {
  if (typeof google === 'undefined') return;
  // Init Google OneTap
  google.accounts.id.initialize({
    client_id: firebaseGoogleClientId,
    callback: function (response) {
      const credential = firebase.auth.GoogleAuthProvider.credential(response.credential);
      firebase.auth().signInWithCredential(credential);
    },
  });

  // Get Google OneTap prompt eligiblity (*based on time since last display)
  const oneTapPromptState = sessionStorage.getItem('googleonetap-promptstate');
  const oneTapCurrentTimeMs = Date.now();
  const maxOneTapMemorySeconds = 30;
  if (oneTapPromptState !== null) {
    const priorTapCurrentTimeMs = parseInt(oneTapPromptState);
    let elapsedTapDeltaSeconds = Math.abs(oneTapCurrentTimeMs - priorTapCurrentTimeMs) / 1000;
    if (elapsedTapDeltaSeconds > maxOneTapMemorySeconds) {
      sessionStorage.removeItem('googleonetap-promptstate');
    } else {
      console.log('-- Google OneTap not yet eligible...', elapsedTapDeltaSeconds + 's/' + maxOneTapMemorySeconds + 's'); // prettier-ignore
    }
  }
  // Prompt Google OneTap as eligible
  if (oneTapPromptState === null) {
    sessionStorage.setItem('googleonetap-promptstate', oneTapCurrentTimeMs + '');
    google.accounts.id.prompt();
  } else {
    console.log("-- Google OneTap ineligible - don't prompt");
  }
}

function hideGoogleOneTapLogin() {
  if (typeof google === 'undefined') return;
  sessionStorage.removeItem('googleonetap-promptstate');
  google.accounts.id.cancel();
}

function setPersistence(persistence) {
  firebase.auth().setPersistence(firebase.auth.Auth.Persistence[persistence]);
}

function setUrlParams() {
  const urlParams = new URLSearchParams(document.location.search);
  let destinationUrlValue = urlParams.get('destination_url');
  if (!destinationUrlValue) destinationUrlValue = urlParams.get('destinationurl');
  let quickActionValue = urlParams.get('quickaction');
  destinationUrl = destinationUrlValue;
  quickAction = quickActionValue;
}

const init = () => {
  setUrlParams();
  initFirebaseUi();
  listenAuthStateChange();
};

onMounted(() => {
  init();
});
</script>

<template>
  <div class="hrbr-login-app">
    <div class="hrbr-login">
      <div class="hrbr-login__header">
        <div class="hrbr-login__logo">
          <a class="hrbr-login__logo-link" href="https://www.harbourshare.com" target="_blank">
            <img
              class="hrbr-login__logo-img"
              src="/static/imgs/harbour-logo-new.svg"
              alt="Harbour logo"
              width="80"
              height="80" />
          </a>
        </div>
        <div class="hrbr-login__header-title">Secure Login</div>
      </div>

      <div class="hrbr-login__firebase-container" v-show="isFirebaseUiVisible">
        <div id="firebaseui-auth-container"></div>
      </div>

      <div class="hrbr-login__footer">
        <div class="hrbr-login__success" v-show="isSuccessMsgVisible">
          <div class="hrbr-login__success-check" v-html="checkIconSvg"></div>
          <div class="hrbr-login__success-msg">Redirecting now...</div>
        </div>
        <div class="hrbr-login__links">
          <!-- prettier-ignore -->
          <a href="https://knowledgebase.harbourshare.com/knowledge/how-do-i-log-in-to-harbour" target="_blank">Need login help? Click here</a>
          <br /><br />
          <a href="https://www.harbourshare.com/terms-of-service" target="_blank"
            >Terms of Service</a
          >&nbsp;
          <a href="https://www.harbourshare.com/privacy-policy" target="_blank">Privacy Policy</a>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.hrbr-login-app {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 100px;
  padding-bottom: 50px;
  background-color: #181818;
  background: radial-gradient(#4b4b53, #0f0f13) rgba(62, 62, 68, 0.94);
  min-height: 100vh;
}

.hrbr-login {
  width: 100%;
  max-width: 390px;
  min-height: 490px;
  padding: 45px;
  text-align: center;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 14px 30px rgba(0, 0, 0, 0.13);

  &__logo {
    display: flex;
    justify-content: center;
  }

  &__logo-img {
    display: block;
  }

  &__header-title {
    font-size: 22px;
    color: #222222;
    line-height: 1.2;
    text-align: center;
    margin-top: 5px;
  }

  &__firebase-container {
    margin-top: 30px;

    :deep(.firebaseui-idp-icon) {
      display: inline-block;
      width: 24px;
      height: 24px;
      vertical-align: middle;
      background-color: #ffffffe8;
      padding: 3px;
      border-radius: 2px;
      box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
      border: none;
    }

    :deep(.firebaseui-idp-list > .firebaseui-list-item:last-child) {
      position: relative;
    }

    :deep(.firebaseui-idp-list > .firebaseui-list-item:last-child::before) {
      content: '';
      width: 100%;
      height: 1px;
      background: #dbdbdb99;
      position: absolute;
      top: 8px;
      left: 0;
    }

    :deep(.firebaseui-idp-google > .firebaseui-idp-text) {
      color: #fff;
    }

    :deep(.firebaseui-idp-password) {
      display: inline-flex;
      font-weight: 400;
      text-align: center;
      width: auto;
      max-width: 100%;
      min-height: 0;
      padding: 0;
      margin-top: 20px;
      box-shadow: none !important;
    }

    :deep(.firebaseui-idp-password .firebaseui-idp-text) {
      display: inline;
      color: #0e53a2bd;
      padding-left: 0;
      font-size: 13px;
      padding-top: 8px;
    }

    :deep(.firebaseui-idp-password .firebaseui-idp-text:hover) {
      text-decoration: underline;
    }

    :deep(.firebaseui-idp-password .firebaseui-idp-text-short),
    :deep(.firebaseui-idp-password .firebaseui-idp-icon-wrapper) {
      display: none;
    }
  }

  &__success {
    font-size: 13px;
    color: #929292;
    line-height: 1.2;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 45px;
  }

  &__success-check {
    color: green;
    height: 48px;
  }

  &__links {
    line-height: 1.2;
    margin-top: 30px;

    a {
      font-size: 13px;
      font-weight: 500;
      color: #929292;
      line-height: 1.2;
      text-decoration: none;

      &:hover {
        color: #222;
        text-decoration: underline;
      }
    }
  }
}

@media (max-width: 1200px) {
  @supports (height: 100dvh) {
    .hrbr-login-app {
      min-height: 100dvh;
    }
  }
}
</style>

<style>
.firebaseui-dialog.firebaseui-id-dialog {
  display: none !important;
}
</style>
